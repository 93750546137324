<template>
  <div class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">Payment by card</h1>
        <button @click="$router.go(-1)" class="row align-end gap-10 mb-2">
          <s-icon color="white" height="20">chevron-left</s-icon>
          <div class="my-0">Back</div>
        </button>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="center pt-50 pb-20 col">
      <div class="w600 weight-300">
        Enter your Name on your card, and your card details
      </div>
    </section>
    <section class="center py-20 col">
      <div class="w600 weight-300 col gap-20">
        <div class="col gap-10">
          <label for="stripe" class="font-14">Name on card</label>
          <input v-model="name" name="stripe" type="text" class="element" />
        </div>
        <div class="col gap-10">
          <label class="font-14">Card Details</label>
          <div class="element">
            <div id="card-element"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="w600 mx-auto mt-20 justify-end">
      <s-btn :disabled='!complete' :loading="loading" @click.native="pay()" class="green">pay</s-btn>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";
export default {
  data() {
    return {
      stripe: "",
      card: null,
      code: null,
      name: "",
      loading: false,
      complete: false,
    };
  },
  methods: {
    async init() {
      let response = await this.$store.dispatch("payment/stripe", this.id);
      let key = response.stripe.publicKey;
      this.code = response.stripe.code;
      this.stripe = await loadStripe(key);
      const elements = this.stripe.elements();
      this.card = elements.create("card", { style: this.style });
      this.card.mount("#card-element");

      this.card.on("change", (error) => {
        this.complete = error.complete
        if (error.error)
          this.$store.commit("notifications/push", {
            error: error.error.message,
          });
        this.loading = false;
      });
    },
    async pay() {
      let data = {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.name,
          },
        },
      };
      this.loading = true;
      let payment = await this.stripe.confirmCardPayment(this.code, data);
      let ob = { invoiceId: this.id, paymentIntent: payment };
      let res = await this.$store.dispatch("payment/postStripe", ob);
      this.loading = false;
      let route = res.paymentStatus == 'failed' ? 'declined' : 'success';
       this.$router.push(`/licenses/${route}`);
    },
  },
  computed: {
    id() {
      return this.$store.state.invoice.invoice.invoiceNumber;
    },
    style() {
      return {
        base: {
          backgroundColor: "#151b23",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          iconColor: "#ffffff",
          color: "#ffffff",
          "::placeholder": {
            color: "#ffffff",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
    },
    ...mapState("payment", ["invoice", "options"]),
    ...mapState("products", ["products"]),
  },
  watch: {},
  async mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.label {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
}

.item {
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #42515e;
  border-right: 1px solid #42515e;
  border-top: 1px solid #42515e;
}

.grid {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 2px;
  width: 100%;
  transition: 0.2s all ease;
}

.element {
  box-sizing: border-box;
  height: 48px;
  padding: 14px 12px;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  width: 100%;
  border-radius: 5px !important;
  border: solid 1px #6388aa !important;
  background-color: #151b23;
  opacity: 1;
  color: #ffffff;
  font-size: 16px;
  font-family: roboto;
}
</style>